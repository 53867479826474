body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff !important;
  overflow-y: scroll;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
