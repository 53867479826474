.ui.card {
  border-left: 10px solid #74c4b8 !important;
}

.ui.button {
  font-weight: 600;
}

img {
  display: block;
}

.ui.modal .actions {
  text-align: center;
}

.pushable .ui.comment-sidebar.right {
  box-shadow: none;
}

.ui.teal.button.icon {
  color: rgba(0, 0, 0, 0.6);
}

/* Semantic UI broke this feature so we manually reimplement it here */
.ui.menu.borderless {
  border: none;
}

.ui.menu.vertical.borderless {
  box-shadow: none;
}

.ui.inverted.menu .reworkBtn {
  margin-left: 40px;
}

.ui.inverted.menu .finalizeBtn {
  background: #ea468f;
}

.ui.inverted.menu .finalizeBtn:hover {
  background: #ac145a !important;
}

.menu-wrapper .ui.secondary.pointing.menu {
  border-bottom: 0;
}

.menu-wrapper .ui.secondary.pointing.menu {
  padding: 16px 0;

}
.menu-wrapper .ui.secondary.pointing.menu .item {
  border-right: 1px solid;
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.menu-wrapper .ui.secondary.pointing.menu .item:last-of-type {
  border-right: 0;
}

.input--no-border.ui.input input,
.input--no-border .ui.button{
  border-radius: 0 !important;
  height: 2em;
}


