.Navbar {
  position: sticky;
  z-index: 2000;
  top: 0;
}

.Navbar .LargeContainer {
    background: white;
    top: 14px;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
}

.Navbar .LargeContainer div {
    margin-top: 0;
    margin-bottom: 0;
}

.Navbar .LargeContainer,
.Navbar + .BlackHR,
.action-bar-sticky {
  position: sticky !important;
  z-index: 2000;
}

.action-bar-sticky {
    top: 72px;
}

.action-bar-sticky .menu .right-menu {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.action-bar-sticky .menu.right .menu p {
    justify-content: center;
    width: 500px;
}

.Navbar + .BlackHR {
top: 58px;
}

.ui.stuck-container.bound-container .ui.bound.sticky {
top: 108px;
}

.ui.page.modals.dimmer {
    z-index: 3000;
}
@media screen and (max-width: 767px) {
.action-bar-sticky { top: 340px; }
}

